import http from "@/utils/http"

/**
 * 地址
 * @param {object} params
 */

/**
 * 兑换卡卷
 */
export function exchange(params) {
    return http({
        url: "/api/cardcoupon/concert",
        data: params
    })
}
