<template>
    <!-- 应该用elment 后面再改 -->
    <div class="voucher-container">
        <div class="voucher-card">
            <div class="voucher-header">
                <h2>兑换卡卷</h2>
            </div>
            <div class="voucher-body">
                <input v-model="voucherNumber" type="text" placeholder="请输入卡卷账号" />
                <input v-model="voucherPassword" type="password" placeholder="请输入卡卷密码" />
                <button @click="exchangeVoucher">兑换卡卷</button>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
    import { exchange } from "@/api/VoucherRedeem/index"
    import { getToken } from "@/utils/auth"
    import { mapGetters } from "vuex"
    import hashlib from "crypto-js/md5"

    export default {

        data() {
            return {
                voucherNumber: "",
                voucherPassword: "",
            }
        },

        computed: {
            ...mapGetters(["token"])
        },

        methods: {

            encrypt() {
                const fixedString = "haima"
                const combinedString = fixedString + this.voucherNumber + this.voucherPassword
                const md5Hash = hashlib(combinedString).toString()
                this.encryptedValue = md5Hash
            },

            exchangeVoucher() {

                this.encrypt()
                const token = this.token
                
                exchange({
                    token: token,
                    card_coupon_number: this.voucherNumber,
                    card_coupon_password: this.voucherPassword,

                    //加密字符串（固定字符串MD5（haima + 账号 +密码））
                    secret_str: this.encryptedValue

                }).then((res) => {
                    
                    //类型为余额直接展示卡券信息,否则跳转到对应商品详情页
                    if (res.data.card_type === 1){

                        const voucherInfo = {
                            number: res.data.card_coupon_number,
                            password: res.data.card_coupon_password,
                            BeginTime: res.data.expire_start_time_attr,
                            endTime: res.data.expire_end_time_attr,
                            useTime: res.data.curred_time
                        }

                         this.$store.commit('app/SET_VOUCHER_INFO',voucherInfo)
                         this.$router.push("/VoucherRedeem/detail")

                    }else {
                        let skuIds = res.data.coupon_good_ids.split(",");
                        let skuId = skuIds[1];
                        this.$router.push({ path: '/sku-' + skuId })
                    }
                }).catch((err) => {
                    this.$message.error(err.message)
                })
                
            }

        }
    }
</script>

<style>
    .voucher-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        background-color: #fa0001;
    }

    .voucher-card {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        padding: 30px;
    }

    .voucher-header {
        text-align: center;
        margin-bottom: 30px;
    }

    .voucher-header h2 {
        font-size: 24px;
        color: #333;
        margin: 0;
        padding: 0;
    }

    .voucher-body input {
        padding: 12px;
        font-size: 16px;
        margin-bottom: 20px;
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 10px;
        outline: none;
        transition: border-color 0.3s ease-in-out;
    }

    .voucher-body button {
        padding: 12px 24px;
        font-size: 16px;
        cursor: pointer;
        background-color: #4caf50;
        color: #fff;
        border: none;
        border-radius: 10px;
        width: 100%;
        transition: background-color 0.3s ease-in-out;
    }

    .voucher-body button:hover {
        background-color: #45a049;
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .modal-content {
        background-color: #fff;
        padding: 30px;
        border-radius: 10px;
        text-align: center;
    }

    .modal-content h3 {
        margin-bottom: 20px;
    }

    .modal-content p {
        margin-bottom: 10px;
    }

    .modal-content button {
        padding: 12px 24px;
        font-size: 16px;
        cursor: pointer;
        background-color: #4caf50;
        color: #fff;
        border: none;
        border-radius: 10px;
        transition: background-color 0.3s ease-in-out;
    }

    .modal-content button:hover {
        background-color: #45a049;
    }
</style>
